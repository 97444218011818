import React, { useEffect, useState } from "react";
import { Ghost } from "lucide-react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/Components/ui/carousel";
import { Image } from "@/Components/Atoms/Image";
import { BattlestationEditImages } from "@/Components/Organisms/Battlestation/BattlestationEditImages";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { usePage } from "@inertiajs/react";
import type { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { cn } from "@/Utils/shadcn";
import { BattlestationImageCrop } from "@/Components/Organisms/Battlestation/BattlestationImageCrop";
import { create } from "mutative";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";
import { img } from "@/Utils/img";

export const BattlestationImages = () => {
  const { props } = usePage<BattlestationProps>();

  const editing = useBattlestationStore((state) => state.editing);

  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!api) return;

    const onSelect = () => {
      setCurrent(api.selectedScrollSnap());
    };

    api.on("select", onSelect);

    return () => {
      api.off("select", onSelect);
    };
  }, [api]);

  const form = useFormContext<BattlestationForm>();

  const { fields, move, remove, append, update } = useFieldArray({
    control: form.control,
    name: "images",
    keyName: "key",
  });

  const watchedFields = useWatch({
    control: form.control,
    name: "images",
  });

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchedFields[index],
    id: field.id,
  }));

  const images = editing ? controlledFields : props.battlestation.images ?? [];

  const editImages = editing && (
    <BattlestationEditImages images={images} onMove={move} onRemove={remove} onAdd={append} />
  );

  return (
    <div className="relative h-full w-full">
      <div
        className={cn(
          "absolute right-2 top-2 z-10 flex items-center gap-2 sm:right-3 sm:top-3 lg:right-4 lg:top-4",
          images.length === 0 && "hidden",
        )}>
        {editing && images.length ? (
          <BattlestationImageCrop
            image={images[current] ?? undefined}
            name={`images.${current}`}
            onCrop={(crop) => {
              update(
                current,
                create(images[current], (draft) => {
                  draft.crop = crop;
                }),
              );
            }}
          />
        ) : null}
        {editImages}
      </div>
      {images.length ? null : (
        <div className="flex h-full w-full flex-col items-center justify-center gap-6">
          <div className="rounded-full bg-muted p-5 text-muted-foreground">
            <Ghost className="size-10" />
          </div>
          <div className="flex flex-col items-center gap-1">
            <h2 className="text-2xl font-bold tracking-tight">There's nothing here.</h2>
            <p className="text-center text-sm text-muted-foreground">
              Add some images to showcase your setup!
            </p>
          </div>
          {editImages}
        </div>
      )}
      {images.length ? (
        <Carousel
          setApi={setApi}
          opts={{
            align: "center",
            loop: true,
          }}
          className="flex h-full flex-col">
          <CarouselContent className="h-full">
            {images.map((image) => (
              <CarouselItem key={image.key} className="relative flex h-full justify-center">
                <div
                  style={{
                    backgroundImage: `url(${img(image, { named: "battlestation_full" })})`,
                    maskImage: `linear-gradient(to right, transparent, black 30%, black 70%, transparent)`,
                  }}
                  className="absolute inset-0 bg-cover bg-center bg-no-repeat blur-md brightness-50"
                />
                <Image
                  image={image}
                  transform={{ named: "battlestation_full" }}
                  className="relative flex size-full items-center justify-center object-contain"
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext className="right-3 flex touch:hidden" />
          <CarouselPrevious className="left-3 flex touch:hidden" />
          <div className="pointer-events-none absolute inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-background to-transparent pb-4 pt-24">
            <CarouselDots className="pointer-events-auto" />
          </div>
        </Carousel>
      ) : null}
    </div>
  );
};
