import { ImageTransform } from "@/Types/ImageTransform";
import { imageKit } from "@/Utils/imageKit";
import { Transformation } from "imagekit-javascript/dist/src/interfaces/Transformation";

type ImageInput = App.Data.Models.ImageData | string | null | undefined;

type InferReturnType<T extends ImageInput> = T extends null | undefined ? undefined : string;

export const img = <T extends ImageInput>(
  image: T,
  transforms?: ImageTransform,
  preventCrop?: boolean,
): InferReturnType<T> => {
  if (!image) {
    return undefined as InferReturnType<T>;
  }

  if (typeof image === "string") {
    return image as InferReturnType<T>;
  }

  const transformation: Transformation[] = [];

  if (!transforms) {
    transforms = [];
  }

  if (!Array.isArray(transforms)) {
    transforms = [transforms];
  }

  if (!preventCrop && image.crop) {
    transformation.push(crop(image.crop));
  }

  for (const transform of transforms) {
    let builder: Transformation = {};

    if (transform?.crop) {
      builder = Object.assign(builder, crop(transform.crop));
    }

    if (transform?.aspectRatio) {
      builder.aspectRatio = transform.aspectRatio;
    }

    if (transform?.width) {
      builder.width = transform.width.toString();
    }

    if (transform?.named) {
      builder.named = transform.named;
    }

    transformation.push(builder);
  }

  return imageKit.url({
    path: image.key,
    transformation,
  }) as InferReturnType<T>;
};

const crop = (crop: App.Data.CropData): Transformation => {
  const cropX = crop.x / 100;
  const cropY = crop.y / 100;

  const cropWidth = crop.width / 100;
  const cropHeight = crop.height / 100;

  return {
    cropMode: "extract",
    x: `iw_mul_${cropX}`,
    y: `ih_mul_${cropY}`,
    width: `iw_mul_${cropWidth}`,
    height: `ih_mul_${cropHeight}`,
  };
};
