import React, { useRef } from "react";
import { SettingsLayout } from "@/Layouts/SettingsLayout";
import { route } from "ziggy-js";
import { useFileUploadsStore } from "@/Stores/useFileUploadsStore";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/Components/ui/form";
import { Input } from "@/Components/ui/input";
import { Checkbox } from "@/Components/ui/checkbox";
import { Textarea } from "@/Components/ui/textarea";
import { Avatar } from "@/Components/Atoms/Avatar";
import { uploadFileToS3 } from "@/Utils/uploadFileToS3";
import { FormImage } from "@/Types/FormImage";
import { Button } from "@/Components/ui/button";
import { Label } from "@/Components/ui/label";
import { fileSchema } from "@/Schemas/fileSchema";
import { FormFileInput } from "@/Components/Molecules/FormFileInput";
import { purgeUUIDs } from "@/Utils/purgeUUIDs";
import { uuid } from "@/Utils/uuid";

export type AccountProps = {
  user: App.Data.Models.UserData;
};

const formSchema = z.object({
  username: z.string(),
  email: z.string(),
  subscribe_to_announcements: z.boolean(),
  subscribe_to_activity: z.boolean(),
  about: z.string(),
  avatar: fileSchema.nullable(),
  banner_image: fileSchema.nullable(),
});

const Account = (props: AccountProps) => {
  const avatarRef = useRef<HTMLInputElement>(null);
  const bannerImageRef = useRef<HTMLInputElement>(null);

  const uploadsInProgress = useFileUploadsStore((state) => state.uploadsInProgress);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: props.user.username,
      email: props.user.email!,
      subscribe_to_announcements: !!props.user.announcements_subscribed_at!,
      subscribe_to_activity: !!props.user.activity_subscribed_at!,
      about: props.user.about ?? "",
      avatar: props.user.avatar,
      banner_image: props.user.banner_image,
    },
  });

  return (
    <Form
      {...form}
      route={route("settings.account.store")}
      options={{
        transform: (data) =>
          Object.assign(data, {
            avatar: purgeUUIDs(data.avatar),
            banner_image: purgeUUIDs(data.banner_image),
          }),
      }}
      onReset={() => {
        if (avatarRef.current) {
          avatarRef.current.value = "";
        }

        if (bannerImageRef.current) {
          bannerImageRef.current.value = "";
        }
      }}>
      <SettingsLayout
        form={form}
        disabled={uploadsInProgress > 0}
        title="Profile"
        description="This information will be displayed publicly so be careful what you share.">
        <div className="col-span-3 sm:col-span-2">
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <Input placeholder="Enter username..." autoComplete="username" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="col-span-3 sm:col-span-2">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="Enter email..." autoComplete="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="col-span-3 space-y-2 sm:col-span-2">
          <Label>Email preferences</Label>
          <FormField
            control={form.control}
            name="subscribe_to_announcements"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4 shadow">
                <FormControl>
                  <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>Newsletter</FormLabel>
                  <FormDescription>
                    Receive product updates and community announcements.
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="subscribe_to_activity"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4 shadow">
                <FormControl>
                  <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>New activity</FormLabel>
                  <FormDescription>
                    Receive notifications when there's new activity on rigr.gg.
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
        </div>
        <div className="col-span-3">
          <FormField
            control={form.control}
            name="about"
            render={({ field }) => (
              <FormItem>
                <FormLabel>About me</FormLabel>
                <FormControl>
                  <Textarea placeholder="Write something about yourself..." rows={3} {...field} />
                </FormControl>
                <FormDescription>Brief description for your profile.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {/* Avatar */}
        <div className="col-span-3">
          <FormField
            control={form.control}
            name="avatar"
            render={({ field: { value, onChange, ...field } }) => (
              <FormItem>
                <FormLabel>Avatar</FormLabel>
                <FormControl>
                  <div className="mt-2 flex items-center gap-2">
                    <Avatar image={value} username={props.user.username} className="size-9" />
                    <div className="flex gap-2">
                      <input
                        {...field}
                        id="avatar"
                        name="avatar"
                        type="file"
                        accept="image/png, image/jpeg"
                        className="sr-only absolute"
                        tabIndex={-1}
                        onInput={async (e) => {
                          const target = e.target as HTMLInputElement;
                          if (!target.files?.length) return;

                          e.preventDefault();

                          const file = target.files[0];

                          const uploadResponse = await uploadFileToS3(file);
                          const formData: FormImage = {
                            id: uuid(),
                            name: file.name,
                            key: uploadResponse.key,
                            upload_response: uploadResponse,
                          };

                          form.setValue("avatar", formData, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }}
                      />
                      <Button type="button" variant="secondary" className="relative">
                        Change<span className="sr-only"> avatar</span>
                        <label htmlFor="avatar" className="absolute inset-0 cursor-pointer" />
                      </Button>
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {/* Banner image */}
        <div className="col-span-3">
          <FormField
            control={form.control}
            name="banner_image"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Banner image</FormLabel>
                  <FormControl>
                    <FormFileInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </div>
      </SettingsLayout>
    </Form>
  );
};

export default Account;
