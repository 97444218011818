import React from "react";
import { route } from "ziggy-js";
import { Link } from "@inertiajs/react";
import { Image } from "@/Components/Atoms/Image";
import { MessageCircle, ThumbsUp } from "lucide-react";
import { FormattedNumber } from "@/Components/Atoms/FormattedNumber";
import { Avatar } from "@/Components/Atoms/Avatar";

export type BattlestationCardDetailedProps = {
  battlestation: App.Data.Models.BattlestationData;
};

export const BattlestationCardDetailed = (props: BattlestationCardDetailedProps) => {
  return (
    <Link
      href={route("battlestation.show", {
        battlestation: props.battlestation,
      })}
      className="min-w-0">
      <div className="group flex h-full flex-col items-center justify-between rounded-2xl border bg-card p-4 transition-all will-change-transform hover:-translate-y-0.5 hover:transform hover:border-gray-700 hover:shadow-xl hover:shadow-indigo-800/10 active:scale-[99%]">
        <Image
          image={props.battlestation.images![0]}
          transform={{ named: "battlestation_thumb" }}
          alt="Battlestation image"
          className="aspect-[4/3] w-full rounded-lg border object-cover"
        />
        <div className="mt-4 flex w-full flex-1 flex-col justify-between gap-2 rounded-b-md">
          <div
            className="line-clamp-1 text-ellipsis break-words text-lg font-medium leading-tight"
            title={props.battlestation.name}>
            {props.battlestation.name}
          </div>
          <div className="line-clamp-2 text-ellipsis break-words text-sm leading-tight text-gray-300">
            {props.battlestation.about}
          </div>
          <div className="flex gap-2 text-xs leading-tight text-muted-foreground">
            <div className="flex items-center gap-1.5">
              <Avatar
                image={props.battlestation.creator!.avatar}
                username={props.battlestation.creator!.username}
                className="h-4 w-4 rounded-full"
              />
              <span className="line-clamp-1 text-ellipsis text-xs text-muted-foreground">
                {props.battlestation.creator!.username}
              </span>
            </div>
            <span className="text-xs">&bull;</span>
            <div className="flex items-center gap-1">
              <ThumbsUp className="size-3" />{" "}
              <FormattedNumber number={props.battlestation.points ?? 0} />
            </div>
            <span className="text-xs">&bull;</span>
            <div className="flex items-center gap-1">
              <MessageCircle className="size-3" />{" "}
              <FormattedNumber number={props.battlestation.comments_count ?? 0} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
