import React, { useState } from "react";
import { FileInput } from "@/Components/Atoms/FileInput";
import { FormImage } from "@/Types/FormImage";
import { cn } from "@/Utils/shadcn";
import { Grip, Images, X } from "lucide-react";
import { Button } from "@/Components/ui/button";
import { DialogWrapper } from "@/Components/Molecules/DialogWrapper";
import { useWindowSizeThreshold } from "@/Hooks/useWindowSizeThreshold";
import { Breakpoint } from "@/Types/Enums/Breakpoint";
import { useFormContext, useWatch } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { FormField } from "@/Components/ui/form";
import { Input } from "@/Components/ui/input";
import { FormErrorsAlert } from "@/Components/Molecules/FormErrorsAlert";
import { ErrorBadge } from "@/Components/Atoms/ErrorBadge";
import { useFormErrors } from "@/Hooks/useFormErrors";
import { useHasErrors } from "@/Hooks/useHasErrors";
import { TextProgress } from "@/Components/Molecules/TextProgress";
import { IMAGE_LIMIT } from "@/Utils/constants";
import { useUploadImages } from "@/Hooks/useUploadImages";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { createPortal } from "react-dom";
import { img } from "@/Utils/img";

export type BattlestationEditImagesProps = {
  images: Array<FormImage>;
  onMove: (from: number, to: number) => void;
  onRemove: (index: number) => void;
  onAdd: (images: Array<FormImage>) => void;
};

export const BattlestationEditImages = (props: BattlestationEditImagesProps) => {
  const isSmall = useWindowSizeThreshold(Breakpoint.SM);

  const [uploading, upload] = useUploadImages();

  const errors = useFormErrors<BattlestationForm>([
    "images",
    "images.*.name",
    "images.*.upload_response.key",
  ]);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <div className="flex items-center gap-2">
      <DialogWrapper
        title="Edit Images"
        description="Add, remove, and reorder images."
        trigger={
          <Button
            variant="secondary"
            size={isSmall ? "sm" : "default"}
            className="rounded-xl bg-secondary/80 shadow-sm backdrop-blur-sm hover:bg-secondary/60 md:rounded-md">
            <div className="mr-1.5">
              {errors.length ? (
                <ErrorBadge className="size-3.5" />
              ) : (
                <Images className="size-3.5" />
              )}
            </div>
            Edit Images
          </Button>
        }>
        <div className="flex flex-col gap-4">
          {errors.length ? <FormErrorsAlert errors={errors} /> : null}
          {props.images.length ? (
            <div data-vaul-no-drag="true" className="flex flex-col gap-2">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={(event) => {
                  const { active } = event;
                  if (!active.id) return;

                  setActiveIndex(props.images.findIndex((image) => image.id === active.id));

                  document.body.classList.add("global-grabbing");
                }}
                onDragEnd={(event) => {
                  document.body.classList.remove("global-grabbing");

                  const { active, over } = event;
                  if (active.id === over?.id) return;

                  const oldIndex = props.images.findIndex((image) => image.id === active.id);
                  const newIndex = props.images.findIndex((image) => image.id === over?.id);

                  setActiveIndex(null);

                  props.onMove(oldIndex, newIndex);
                }}
                onDragCancel={() => {
                  document.body.classList.remove("global-grabbing");
                }}>
                <SortableContext items={props.images} strategy={verticalListSortingStrategy}>
                  {props.images.map((image, index) => (
                    <DraggableImage
                      key={image.id}
                      item={image}
                      isOverlay={false}
                      onRemove={() => props.onRemove(index)}
                    />
                  ))}
                </SortableContext>
                {createPortal(
                  <DragOverlay>
                    {activeIndex !== null ? (
                      <DraggableImage
                        item={props.images[activeIndex]}
                        isOverlay={true}
                        onRemove={() => props.onRemove(activeIndex)}
                      />
                    ) : null}
                  </DragOverlay>,
                  document.body,
                )}
              </DndContext>
            </div>
          ) : null}
          <TextProgress entity="images" value={props.images.length} limit={IMAGE_LIMIT} />
          <FileInput
            uploading={uploading}
            disabled={props.images.length >= IMAGE_LIMIT}
            onChange={async (files) => {
              const newImages = await upload(files, IMAGE_LIMIT - props.images.length);
              if (newImages) {
                props.onAdd(newImages);
              }
            }}
            multiple
          />
        </div>
      </DialogWrapper>
    </div>
  );
};

type DraggableImageProps = {
  item: FormImage;
  isOverlay: boolean;
  onRemove: () => void;
};

const DraggableImage = (props: DraggableImageProps) => {
  const form = useFormContext<BattlestationForm>();

  // passing in the name causes the image to be re-rendered and briefly flash
  // the wrong widget data when two images are swapped. by using the image id
  // to get the index instead, we can avoid this issue
  const index = form.getValues("images").findIndex((x) => x.id === props.item.id);

  const name = `images.${index}` as const;

  const fieldWatch = useWatch({
    control: form.control,
    name: name,
  });

  const hasErrors = useHasErrors<BattlestationForm>([
    `${name}.name`,
    `${name}.upload_response.key`,
  ]);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const isPlaceholder = isDragging && !props.isOverlay;

  return (
    <div
      ref={props.isOverlay ? undefined : setNodeRef}
      style={props.isOverlay ? undefined : style}
      className={cn(
        "flex items-center justify-between gap-2 rounded-2xl border bg-card p-2",
        hasErrors && "border-destructive",
        isPlaceholder && "border-dashed border-gray-700",
      )}>
      <div className={cn("flex flex-1 items-center gap-2", isPlaceholder && "opacity-0")}>
        <div className="flex shrink-0 items-center gap-2">
          <div
            className={cn(
              "group shrink-0 cursor-grab touch-manipulation focus-visible:outline-none",
              isDragging && "cursor-grabbing",
            )}
            {...(props.isOverlay ? {} : { ...attributes, ...listeners })}>
            <Grip
              className={cn(
                "size-5 hover:text-gray-100 focus:text-gray-100 group-focus-visible:text-gray-100",
                props.isOverlay ? "text-gray-100" : "text-gray-500",
              )}
            />
          </div>
          <div className="relative aspect-[4/3] h-16 shrink-0">
            <img
              src={img(fieldWatch, { named: "battlestation_full" })}
              alt={fieldWatch.name!}
              className="h-full w-full rounded-md object-cover object-center"
            />
          </div>
        </div>
        <FormField
          control={form.control}
          name={`${name}.name`}
          render={({ field: { value, ...field } }) => (
            <Input {...field} value={value ?? ""} placeholder="Enter filename..." maxLength={50} />
          )}
        />
      </div>
      <Button
        variant="link"
        size="icon"
        onClick={props.onRemove}
        className={cn("w-4", isPlaceholder && "opacity-0")}>
        <X className="size-5" />
      </Button>
    </div>
  );
};
