import { useEffect, useCallback } from "react";
import { FormImage } from "@/Types/FormImage";
import { ImageTransform } from "@/Types/ImageTransform";
import { img } from "@/Utils/img";

export const usePreloadImages = (
  images: Array<App.Data.Models.ImageData | FormImage | null | undefined> | undefined,
  transform?: ImageTransform,
  fullSizeTransform?: ImageTransform,
  preventCropForFullSize?: boolean,
) => {
  const preloadImages = useCallback(
    (imagesToLoad: Array<App.Data.Models.ImageData | FormImage>, isFullSize: boolean) => {
      return new Promise<void>((resolve) => {
        let loadedCount = 0;
        const totalImages = imagesToLoad.length;

        if (totalImages === 0) {
          resolve();
          return;
        }

        const checkAllLoaded = () => {
          if (loadedCount === totalImages) {
            resolve();
          }
        };

        imagesToLoad.forEach((image) => {
          const imgElement = new Image();
          imgElement.onload = imgElement.onerror = () => {
            loadedCount++;
            checkAllLoaded();
          };
          imgElement.src = isFullSize
            ? img(image, fullSizeTransform, preventCropForFullSize)
            : img(image, transform);
        });
      });
    },
    [transform, fullSizeTransform, preventCropForFullSize],
  );

  useEffect(() => {
    if (!images) return;

    const validImages = images.filter(
      (image): image is NonNullable<(typeof images)[number]> => image != null,
    );

    let isCancelled = false;

    const loadImages = async () => {
      // Load transformed images
      await preloadImages(validImages, false);
      if (isCancelled) return;

      // Preload full-size images
      if (fullSizeTransform) {
        await preloadImages(validImages, true);
      }
    };

    loadImages();

    return () => {
      isCancelled = true;
    };
  }, [images, preloadImages]);
};
