import React from "react";
import { route } from "ziggy-js";
import { Link } from "@inertiajs/react";
import { cn } from "@/Utils/shadcn";
import { Image } from "@/Components/Atoms/Image";
import { MessageCircle, ThumbsUp } from "lucide-react";
import { FormattedNumber } from "@/Components/Atoms/FormattedNumber";
import { Avatar } from "@/Components/Atoms/Avatar";
import { formatDate } from "@/Utils/formatDate";
import { Badge } from "@/Components/ui/badge";

export type BattlestationCardSmallProps = {
  battlestation: App.Data.Models.BattlestationData;
  imageClassName?: string;
  className?: string;
};

export const BattlestationCardSmall = (props: BattlestationCardSmallProps) => {
  return (
    <Link
      href={route("battlestation.show", {
        battlestation: props.battlestation,
      })}
      className="min-w-0">
      <div
        className={cn(
          "group flex items-center justify-between overflow-hidden rounded-2xl border bg-card p-2 transition-all will-change-transform hover:-translate-y-0.5 hover:transform hover:border-gray-700 hover:shadow-xl hover:shadow-indigo-800/10 active:scale-[98%]",
          props.className,
        )}>
        <div className="flex items-center overflow-hidden">
          <Image
            image={props.battlestation.images![0]}
            transform={{ named: "battlestation_thumb" }}
            alt="Battlestation image"
            className={cn(
              "group-hover:brightness-115 aspect-square h-24 shrink-0 rounded-lg border object-cover shadow-xl sm:aspect-[4/3]",
              props.imageClassName,
            )}
          />
          <div className="flex flex-col justify-center overflow-hidden px-4">
            {props.battlestation.creator ? (
              <div className="mb-0.5 flex items-center gap-1.5">
                <Avatar
                  image={props.battlestation.creator!.avatar}
                  username={props.battlestation.creator!.username}
                  className="h-4 w-4 rounded-full"
                />
                <span className="line-clamp-1 text-ellipsis whitespace-pre-line break-words text-xs text-muted-foreground">
                  {props.battlestation.creator!.username}
                </span>
              </div>
            ) : (
              <span className="mb-1.5 flex items-center gap-2 text-xs text-muted-foreground">
                {formatDate(props.battlestation.created_at)}
                {props.battlestation.published_at === null && (
                  <Badge variant="outline">Draft</Badge>
                )}
              </span>
            )}
            <div
              className="line-clamp-2 text-ellipsis text-balance break-words text-base font-medium leading-tight"
              title={props.battlestation.name}>
              {props.battlestation.name}
            </div>
            <div className="mt-1.5 flex gap-2 text-xs leading-tight text-muted-foreground">
              <div className="flex items-center gap-1">
                <ThumbsUp className="size-3" />{" "}
                <FormattedNumber number={props.battlestation.points ?? 0} />
              </div>
              <span className="text-xs">&bull;</span>
              <div className="flex items-center gap-1">
                <MessageCircle className="size-3" />{" "}
                <FormattedNumber number={props.battlestation.comments_count ?? 0} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
