import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import { CreateForm } from "@/API/Forms/CreateForm";
import { FormControl, FormField, FormItem, FormMessage } from "@/Components/ui/form";
import { Button } from "@/Components/ui/button";
import { Plus, X } from "lucide-react";
import { TAG_LIMIT } from "@/Utils/constants";
import { CreateLayout } from "@/Layouts/CreateLayout";
import { Popover, PopoverContent, PopoverTrigger } from "@/Components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/Components/ui/command";
import { usePage } from "@inertiajs/react";
import type { CreateProps } from "@/Pages/Battlestation/Create/Create";

export type StepThreeProps = {
  setStep: Dispatch<SetStateAction<number>>;
};

const StepThree = (props: StepThreeProps) => {
  const page = usePage<CreateProps>();

  const form = useFormContext<CreateForm>();

  const {
    fields: tags,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: "stepThree.tags",
    keyName: "key",
  });

  const state = useFormState({
    control: form.control,
    name: "stepThree.tags",
    exact: true,
  });

  const availableTags = useMemo(() => {
    const selectedIds = tags.map((tag: App.Data.Models.TagData) => tag.id);
    return page.props.available_tags.filter((tag) => !selectedIds.includes(tag.id));
  }, [page.props.available_tags, tags]);

  const [open, setOpen] = useState(false);

  return (
    <CreateLayout
      step={3}
      form={form}
      dirty={state.isDirty}
      onNext={async () => {
        let isValid = await form.trigger("stepThree.tags");
        if (!isValid) return;

        props.setStep((step) => step + 1);
      }}
      onBack={() => {
        props.setStep((step) => step - 1);
      }}>
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col items-center gap-2">
          <h2 className="text-2xl font-bold leading-tight tracking-tight">Add some tags</h2>
          <p className="text-balance text-center text-sm text-muted-foreground">
            Choose up to five categories that describe your setup.
          </p>
        </div>
        <FormField
          control={form.control}
          name="stepThree.tags"
          render={() => (
            <FormItem className="flex w-full flex-col items-center gap-4">
              {/* tags preview */}
              {tags.length ? (
                <div className="flex flex-wrap items-center gap-2">
                  {tags.map((tag, index) => (
                    <div
                      key={tag.key}
                      className="flex h-8 items-center gap-1 overflow-hidden rounded-md bg-muted px-2 text-xs text-muted-foreground">
                      <div>#{tag.name}</div>
                      <button
                        type="button"
                        className="-mr-0.5 flex h-6 items-center justify-center hover:text-primary"
                        onClick={() => remove(index)}>
                        <X className="size-3.5" />
                      </button>
                    </div>
                  ))}
                </div>
              ) : null}
              <FormControl>
                {/* add tags dropdown */}
                <Popover open={open} onOpenChange={setOpen} modal>
                  <PopoverTrigger asChild>
                    <Button disabled={tags.length >= TAG_LIMIT} variant="outline">
                      <Plus className="mr-1.5 size-3.5" />
                      Add tag
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent align="start" className="w-[200px] p-0">
                    <Command>
                      <CommandInput placeholder="Search tags..." />
                      <CommandList>
                        <CommandEmpty>No tags found.</CommandEmpty>
                        <CommandGroup>
                          {availableTags?.map((tag) => (
                            <CommandItem
                              key={tag.id}
                              value={tag.name}
                              onSelect={() => {
                                append(tag);
                                setOpen(false);
                              }}>
                              #{tag.name}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </CreateLayout>
  );
};

export default StepThree;
