export const purgeUUIDs = (data: any): any => {
  // If the data is an array, map over it and call purgeUUIDs on each element
  if (Array.isArray(data)) {
    return data.map(purgeUUIDs);
  }

  // If the data is an object, map over it and call purgeUUIDs on each value
  if (typeof data === "object") {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (key === "id" && typeof value === "string") {
          return [key, null];
        }
        if (typeof value === "object" && value != null) {
          return [key, purgeUUIDs(value)];
        }
        return [key, value];
      }),
    );
  }

  // If the data is anything else, return it as is
  return data;
};
