import React, { ReactNode } from "react";
import { Container } from "@/Components/Atoms/Container";
import { cn } from "@/Utils/shadcn";
import { Image, DynamicImage } from "@/Components/Atoms/Image";

export type ImageBannerProps = {
  image?: DynamicImage;
  overlayClassName?: string;
  children?: ReactNode;
};

export const ImageBanner = (props: ImageBannerProps) => {
  return (
    <div className="relative flex h-48 flex-col overflow-hidden lg:h-56">
      {/* Image */}
      {props.image ? (
        <Image
          image={props.image}
          transform={{ named: "banner_image" }}
          alt=""
          className="h-full w-full scale-105 object-cover object-center blur-md"
        />
      ) : (
        <div className="h-full w-full bg-indigo-700" />
      )}
      {/* Gradient overlay */}
      <div
        className={cn(
          "b-0 absolute h-full w-full bg-gradient-to-b from-transparent to-background",
          props.overlayClassName,
        )}
      />
      {/* Content */}
      <div className="absolute flex h-full w-full items-center justify-center">
        <Container>{props.children}</Container>
      </div>
    </div>
  );
};
