export enum AppPermission {
  "EditAllBattlestations" = "battlestations.edit",
  "DeleteAllBattlestations" = "battlestations.delete",
  "SetFeaturedBattlestation" = "battlestations.featured.set",
  "EditAllComments" = "comments.edit",
  "DeleteAllComments" = "comments.delete",
  "ViewAllUsers" = "users.view",
  "EditAllUsers" = "users.edit",
  "DeleteAllUsers" = "users.delete",
  "SuspendUsers" = "users.suspend",
  "ImpersonateUsers" = "users.impersonate",
  "ViewAllTags" = "tags.view",
  "CreateTags" = "tags.create",
  "EditAllTags" = "tags.edit",
  "DeleteAllTags" = "tags.delete",
}
export enum BattlestationSorting {
  "Popular" = 1,
  "Recent" = 2,
  "Active" = 3,
  "Updated" = 4,
}
export enum CardType {
  "None" = 0,
  "Displays" = 1,
  "Decoration" = 2,
  "PCBuild" = 3,
  "Peripherals" = 4,
  "Audio" = 5,
  "MusicProduction" = 6,
  "Lighting" = 7,
  "Gaming" = 8,
  "Devices" = 9,
  "KeyboardBuild" = 10,
}
export enum CommentSorting {
  "Popular" = 1,
  "Recent" = 2,
}
export enum FeaturedBattlestationLocation {
  "FrontPage" = 1,
}
export enum FieldType {
  "None" = 0,
  "Monitor" = 1,
  "MonitorArm" = 2,
  "TV" = 3,
  "Wallpaper" = 4,
  "Desk" = 5,
  "DeskDimensions" = 6,
  "Chair" = 7,
  "Shelving" = 8,
  "Accessory" = 9,
  "Backboard" = 10,
  "WallDecoration" = 11,
  "Case" = 12,
  "VideoCard" = 13,
  "CPU" = 14,
  "Motherboard" = 15,
  "CPUCooler" = 16,
  "CaseFan" = 17,
  "RAM" = 18,
  "PowerSupply" = 19,
  "SSD" = 20,
  "HDD" = 21,
  "Keyboard" = 22,
  "Mouse" = 23,
  "Webcam" = 24,
  "WebcamStand" = 25,
  "USBHub" = 26,
  "DockingStation" = 27,
  "USBAccessory" = 28,
  "Speakers" = 29,
  "SpeakerStand" = 30,
  "Subwoofer" = 31,
  "Amplifier" = 32,
  "Microphone" = 33,
  "MicrophoneStand" = 34,
  "AudioInterface" = 35,
  "Headphones" = 36,
  "HeadphoneStand" = 37,
  "HeadphoneAmp" = 38,
  "AudioAccessory" = 39,
  "DAC" = 40,
  "DAWController" = 41,
  "MIDIInterface" = 42,
  "Instrument" = 43,
  "AudioMixer" = 44,
  "Lamp" = 45,
  "LightBar" = 46,
  "LightStrip" = 47,
  "RGBLight" = 48,
  "VideoLight" = 49,
  "Console" = 50,
  "SteeringWheel" = 51,
  "Joystick" = 52,
  "FlightController" = 53,
  "Pedal" = 54,
  "Laptop" = 55,
  "LaptopStand" = 56,
  "Tablet" = 57,
  "TabletStand" = 58,
  "Phone" = 59,
  "PhoneStand" = 60,
  "AssistantDevice" = 61,
  "Mousepad" = 62,
  "DrawingTablet" = 63,
  "VRHeadset" = 64,
  "Plant" = 65,
  "ThreeDPrinter" = 66,
  "OperatingSystem" = 67,
  "ThermalPaste" = 68,
  "UPS" = 69,
  "NetworkAdapter" = 70,
  "PCGamepad" = 71,
  "CaptureCard" = 72,
  "KeyboardPedal" = 73,
  "GuitarPedal" = 74,
  "PedalBoard" = 75,
  "PopFilter" = 76,
  "MusicStreamer" = 77,
  "MIDIController" = 78,
  "DrumMachine" = 79,
  "CustomCable" = 80,
  "VRHeadStrap" = 81,
  "VRCharger" = 82,
  "PowerBank" = 83,
  "ConsoleGamepad" = 84,
  "CustomCables" = 85,
  "Earpads" = 86,
  "Printer" = 87,
  "Modem" = 88,
  "Router" = 89,
  "Camera" = 90,
  "DrawingTabletStand" = 91,
  "TubeLight" = 92,
  "Drawer" = 93,
  "Cupboard" = 94,
  "Bookcase" = 95,
  "Footrest" = 96,
  "Cat" = 97,
  "Dog" = 98,
  "KeyboardCase" = 99,
  "Switches" = 100,
  "SwitchFilms" = 101,
  "Keycaps" = 102,
  "KeyboardCable" = 103,
  "StarterKit" = 104,
  "Plate" = 105,
  "PCB" = 106,
  "Stabilizers" = 107,
  "Lube" = 108,
  "KeyboardAccessory" = 109,
  "SensorPanel" = 110,
}
export enum ImageRole {
  "None" = 0,
  "Avatar" = 1,
  "BannerImage" = 2,
  "BattlestationImage" = 3,
  "WidgetImage" = 4,
}
export enum MilestoneType {
  "None" = 0,
  "FirstLike" = 1,
  "LikeCount" = 2,
}
export enum Plan {
  "Free" = 0,
  "Basic" = 1,
  "Pro" = 2,
}
export enum UserRole {
  "User" = 0,
  "Moderator" = 1,
  "Admin" = 2,
  "SuperAdmin" = 3,
}
export enum UserStatus {
  "Active" = 1,
  "Suspended" = 2,
  "Deleted" = 3,
}
export enum WidgetType {
  "Card" = 1,
  "Image" = 2,
  "Spotlight" = 3,
}
