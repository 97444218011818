import React, { Fragment } from "react";
import { UserNavigationItem } from "@/Components/Organisms/Navbar/Navbar";
import { Link, usePage } from "@inertiajs/react";
import { ResponseBase } from "@/API/ResponseBase";
import { Avatar } from "@/Components/Atoms/Avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";
import { Points } from "@/Components/Atoms/Points";

export type ProfileDropdownProps = {
  userNavigation: UserNavigationItem[][];
};

export const NavbarUserNavigation = (props: ProfileDropdownProps) => {
  const {
    props: { auth },
  } = usePage<ResponseBase>();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="group flex items-center outline-0">
        <Avatar
          image={auth.user?.avatar}
          username={auth.user?.username}
          className="size-8 transition-transform group-active:scale-95"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-40">
        <DropdownMenuLabel>
          <div className="flex flex-col">
            <span className="text-sm font-medium">{auth.user?.username}</span>
            <span className="text-xs text-muted-foreground">
              <Points points={auth.user?.points ?? 0} />
            </span>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {props.userNavigation.map((group, index) => (
          <Fragment key={index}>
            {group.map((item) => (
              <DropdownMenuItem asChild key={item.name}>
                {item.external ? (
                  <a key={item.name} href={item.href} className="w-full">
                    {item.icon && <item.icon className="mr-1.5 size-3.5" />}
                    {item.name}
                  </a>
                ) : (
                  <Link
                    key={item.name}
                    href={item.href}
                    as={(item.method ?? "get") === "get" ? "a" : "button"}
                    method={item.method ?? "get"}
                    aria-current={item.active ? "page" : undefined}
                    className="w-full">
                    {item.icon && <item.icon className="mr-1.5 size-3.5" />}
                    {item.name}
                  </Link>
                )}
              </DropdownMenuItem>
            ))}
            {props.userNavigation.length > 1 && index < props.userNavigation.length - 1 && (
              <DropdownMenuSeparator />
            )}
          </Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
