import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/Components/ui/dialog";
import { GradientScroll } from "@/Components/Atoms/GradientScroll";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/Components/ui/form";
import { useFormContext } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { InlineEdit } from "@/Components/Atoms/InlineEdit";
import { useUploadImages } from "@/Hooks/useUploadImages";
import { FormErrorsAlert } from "@/Components/Molecules/FormErrorsAlert";
import { useFormErrors } from "@/Hooks/useFormErrors";
import { FileInput } from "@/Components/Atoms/FileInput";
import { Image } from "@/Components/Atoms/Image";
import { Button } from "@/Components/ui/button";
import { ArrowUpRight, CircleCheck, CircleX, Crop, Trash } from "lucide-react";
import { TextareaAutosize } from "@/Components/ui/textarea";
import { Input } from "@/Components/ui/input";
import { img } from "@/Utils/img";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/Components/ui/tooltip";
import { Popover, PopoverContent, PopoverTrigger } from "@/Components/ui/popover";
import { asset } from "laravel-vapor";

export type SpotlightDialogProps = {
  name: `widgets.${number}`;
  showErrors: boolean;
  aspect: number;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onOpenCrop: () => void;
};

export const SpotlightEditDialog = (props: SpotlightDialogProps) => {
  const form = useFormContext<BattlestationForm>();

  const [uploading, upload] = useUploadImages(() => {
    return [
      {
        width: props.aspect === 1 ? "200" : "400",
        height: "200",
      },
    ];
  });

  const errors = useFormErrors<BattlestationForm>([`${props.name}.**`]);

  const name: `widgets.${number}.spotlight` = `${props.name}.spotlight`;

  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <DialogContent
        closeClassName="top-6 right-6"
        className="size-full max-h-[min(calc(100svh-2rem),420px)] max-w-[min(calc(100svw-2rem),420px)] overflow-hidden !rounded-3xl p-0">
        <GradientScroll className="flex flex-col gap-4 overflow-y-auto p-6">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-3.5 text-xl font-bold">
              <FormField
                control={form.control}
                name={`${name}.name`}
                render={({ field }) => (
                  <FormItem className="w-full pr-8">
                    <FormControl>
                      <InlineEdit
                        className="m-0 text-xl font-bold"
                        placeholder="Enter product name..."
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </DialogTitle>
          </DialogHeader>
          {props.showErrors && errors.length ? <FormErrorsAlert errors={errors} /> : null}
          <div className="mt-1 flex flex-col gap-5">
            {/* image */}
            <FormField
              control={form.control}
              name={`${name}.image`}
              render={({ field }) => (
                <FormItem className="flex flex-col gap-1">
                  <FormLabel className="self-start">
                    Add an image <span className="text-red-500">*</span>
                  </FormLabel>
                  <Popover>
                    <FormDescription className="text-balance">
                      Featured products should provide a clear image, preferably from your own
                      setup.{" "}
                      <PopoverTrigger asChild>
                        <Button
                          variant="link"
                          className="inline-flex h-auto items-center p-0 text-xs">
                          Example <ArrowUpRight className="size-3.5" />
                        </Button>
                      </PopoverTrigger>
                    </FormDescription>
                    <PopoverContent className="flex w-auto items-center gap-4">
                      <div className="flex flex-col items-center gap-2">
                        <div className="flex items-center gap-1.5 text-xs font-medium text-green-400">
                          <CircleCheck className="size-5 " />
                          Unique image
                        </div>
                        <img
                          src={asset("assets/images/spotlight-widget-examples/good.png")}
                          alt="Example of a unique spotlight image depicting a clear image of a product sitting in someone's setup with nice aesthetic lighting"
                          className="aspect-square w-28 shrink-0 rounded-lg border object-cover shadow-sm"
                        />
                      </div>
                      <div className="flex flex-col items-center gap-2">
                        <div className="flex items-center gap-1.5 text-xs font-medium text-red-400">
                          <CircleX className="size-5 " />
                          Generic image
                        </div>
                        <img
                          src={asset("assets/images/spotlight-widget-examples/bad.png")}
                          alt="Example of a generic spotlight image depicting a marketing image of a product on a solid white background"
                          className="aspect-square w-28 shrink-0 rounded-lg border object-cover shadow-sm"
                        />
                      </div>
                    </PopoverContent>
                  </Popover>
                  <FormControl>
                    {field.value ? (
                      <div className="flex w-full flex-col">
                        {/* image */}
                        <div className="relative h-44 w-full overflow-hidden rounded-2xl border bg-card shadow-sm">
                          <div
                            style={{
                              backgroundImage: `url(${img(field.value, { named: "widget_image" })})`,
                              maskImage: `linear-gradient(to right, transparent, black 30%, black 70%, transparent)`,
                            }}
                            className="absolute inset-0 bg-cover bg-center bg-no-repeat blur-md brightness-50"
                          />
                          <Image
                            image={field.value}
                            transform={{
                              named: "widget_image",
                              aspectRatio: `${props.aspect}-1`,
                            }}
                            className="relative flex size-full items-center justify-center object-contain"
                          />
                          <div className="absolute right-3 top-3 flex flex-col gap-2">
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  onClick={() => form.setValue(`${name}.image`, null)}>
                                  <Trash className="size-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>Remove image</TooltipContent>
                            </Tooltip>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button variant="outline" size="icon" onClick={props.onOpenCrop}>
                                  <Crop className="size-4" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>Crop image</TooltipContent>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <FileInput
                        uploading={uploading}
                        onChange={async (files) => {
                          const newImages = await upload(files, 1);
                          if (newImages) {
                            form.setValue(`${name}.image`, newImages[0]);
                          }
                        }}
                      />
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* description */}
            <FormField
              control={form.control}
              name={`${name}.description`}
              render={({ field }) => (
                <FormItem className="flex flex-col gap-2">
                  <FormLabel htmlFor={`${name}.description`} className="self-start">
                    Description <span className="text-red-500">*</span>
                  </FormLabel>
                  <div className="flex flex-col gap-2">
                    <FormControl>
                      <TextareaAutosize
                        placeholder="Write about your experience with this product..."
                        minRows={4}
                        {...field}
                        id={`${name}.description`}
                        name={`${name}.description`}
                      />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
            {/* product link */}
            <FormField
              control={form.control}
              name={`${name}.product_link`}
              render={({ field }) => (
                <FormItem className="flex flex-col gap-2">
                  <FormLabel className="self-start">
                    Link to product <span className="text-red-500">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Paste a product link here..."
                      autoComplete="off"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* discount code */}
            <FormField
              control={form.control}
              name={`${name}.discount_code`}
              render={({ field }) => (
                <FormItem className="flex flex-col gap-2">
                  <FormLabel className="self-start">Add discount code</FormLabel>
                  <FormControl>
                    <Input placeholder="Add a discount code here..." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </GradientScroll>
      </DialogContent>
    </Dialog>
  );
};
