import React from "react";
import { Image } from "@/Components/Atoms/Image";
import { Avatar } from "@/Components/Atoms/Avatar";
import { cn } from "@/Utils/shadcn";
import { Link } from "@inertiajs/react";
import { route } from "ziggy-js";

export type BattlestationProps = {
  battlestation: App.Data.Models.BattlestationData;
  isActive: boolean;
  isPlaying: boolean;
};

export const FeaturedBattlestationCard = (props: BattlestationProps) => {
  return (
    <Link
      href={route("battlestation.show", {
        battlestation: props.battlestation,
      })}
      className="aspect-16/9 group flex h-full overflow-hidden rounded-2xl bg-card transition-all @container active:scale-[99%]">
      <Image
        image={props.battlestation.images![0]}
        transform={{ named: "battlestation_full" }}
        alt="Battlestation image"
        className="aspect-[4/3] w-full object-cover"
      />
      {/* content gradient overlay */}
      <div className="pointer-events-none absolute inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-background/90 to-transparent pb-4 pt-36" />
      {/* base border */}
      <div className="absolute inset-0 rounded-2xl border" />
      {/* primary border */}
      <div
        className={cn(
          "embla-transitions:pointer-fine:group-hover:opacity-100 embla-transitions:transition-[opacity,transform] absolute inset-0 rounded-2xl border border-primary opacity-[var(--embla-border-opacity)]",
          props.isActive && "embla-transitions:pointer-fine:group-hover:border-2",
        )}
      />
      {/* content */}
      <div
        className={cn(
          "embla-transitions:pointer-fine:group-hover:-translate-y-0.5 embla-transitions:transition-[opacity,transform] embla-transitions:pointer-fine:group-hover:opacity-100 absolute inset-0 flex flex-col justify-end gap-1.5 p-5 opacity-[var(--embla-content-opacity)]",
        )}>
        <h2
          className="line-clamp-2 text-ellipsis break-words text-xl font-bold !leading-tight @xs:text-2xl"
          title={props.battlestation.name}>
          {props.battlestation.name}
        </h2>
        <div className="flex items-center gap-2">
          <Avatar
            image={props.battlestation.creator?.avatar}
            className="size-4 rounded-full @xs:size-6"
          />
          <span className="line-clamp-1 text-xs opacity-80">
            {props.battlestation.creator?.username}
          </span>
        </div>
      </div>
    </Link>
  );
};
